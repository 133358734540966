import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'sh-mute-button',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatIconModule, FlexLayoutModule],
	templateUrl: './mute-button.component.html'
})
export class MuteButtonComponent {
	/**
	 * Determines if the mute button is in mute state.
	 */
	@Input() public isMuted: boolean | undefined = false;

	/**
	 * Determines if some, but not all devices in the room are muted.
	 */
	@Input() public someDevicesMuted = false;

	/**
	 * Disable mute button.
	 */
	@Input() public disabled = false;

	/**
	 * Emits the value of isMuted on mute button click.
	 */
	@Output() public readonly toggleMute = new EventEmitter<boolean>();

	/**
	 * @ignore internal
	 */
	public mute(event: Event): void {
		event.stopPropagation();
		this.toggleMute.emit(!this.isMuted);
	}
}
