export enum ProxiedTransmitterStatus {
	Unknown = 'UNKNOWN',
	Active = 'ACTIVE',
	Charging = 'CHARGING'
}

export interface ProxiedTransmitter {
	name?: string;
	status: ProxiedTransmitterStatus;
	online?: boolean;
	batteryLevel?: number;
	muted?: boolean;
}

export type APXD2ProxiedTransmitters<T = ProxiedTransmitter> = Record<'mic1' | 'mic2', T>;

export type ProxiedTransmitterForInventory = Pick<ProxiedTransmitter, 'batteryLevel'> | null;

export type ProxiedTransmittersForInventory = APXD2ProxiedTransmitters<ProxiedTransmitterForInventory>;
