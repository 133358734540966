import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { Subject } from 'rxjs';

@Component({
	selector: 'sh-feature-request-main',
	templateUrl: './feature-request-main.component.html',
	styleUrls: ['./feature-request-main.component.scss']
})
export class FeatureRequestMainComponent implements OnInit, OnDestroy {
	public formGroup: FormGroup = new FormGroup({});
	public formData = [
		{
			name: 'title',
			validators: []
		},
		{
			name: 'description',
			validators: [Validators.required]
		}
	];
	private readonly destroy$ = new Subject<void>();
	public isFormChanged = true;

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<FeatureRequestMainComponent>,
		public translocoService: TranslocoService
	) {}

	public ngOnInit(): void {
		this.formGroup = this.formBuilder.group({});
		this.formData?.forEach((formControlDetail) => {
			this.formGroup.addControl(formControlDetail.name, new FormControl('', formControlDetail.validators));
		});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public doAction(): void {
		if (this.formGroup.valid) {
			this.dialogRef.close({ data: this.formGroup.value });
		}
	}

	public closeDialog(): void {
		this.dialogRef.close();
	}
}
