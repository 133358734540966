import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable } from 'rxjs';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private readonly http: HttpClient) {}

	public getTranslation(langPath: string): Observable<Translation> {
		return this.http.get(`./assets/i18n/${langPath}.json`);
	}
}
