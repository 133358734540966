import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IBreadcrumb } from '../breadcrumbs';

@Injectable({ providedIn: 'root' })
export class HeaderToolbarService {
	private breadcrumb$ = new BehaviorSubject<IBreadcrumb[]>([]);
	public getImageUrl$ = new BehaviorSubject<string>('');
	public isImageRemoved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public profileChars$ = new BehaviorSubject<string>('');

	/**
	 * Sets the breadcrumb data for the current component/page.
	 * @param breadcrumb - An array of breadcrumb items to set.
	 */
	public setBreadCrumb(breadcrumb: IBreadcrumb[]): void {
		this.breadcrumb$.next(breadcrumb);
	}

	/**
	 * returns the breadcrumb data as an Observable stream.
	 * @returns An Observable of an array of breadcrumb items.
	 */
	public getBreadCrumb$(): Observable<IBreadcrumb[]> {
		return this.breadcrumb$;
	}
}
