/**
 * This method is called to shuffle background colors based on the hash of the email.
 * @param email - email id of logged in user
 * @returns color index calculated from the email hash
 */
export function getEmailColorIndex(email = ''): number {
	let hash = 0,
		i,
		chr;
	if (email.length !== 0) {
		for (i = 0; i < email.length; i++) {
			chr = email.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
	}

	/**
	 * To ensure the colorIndex is always positive, 7 is added to it and a modulus operation is performed
	 * when the colorIndex is negative.
	 */
	let colorIndex = hash % 7;
	if (colorIndex < 0) {
		colorIndex = (colorIndex + 7) % 7;
	}
	return colorIndex;
}
