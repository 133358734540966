import { CommonModule } from '@angular/common';
import {
	AfterContentInit,
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	Renderer2,
	effect,
	signal
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { NgxResizeObserverModule } from 'ngx-resize-observer';

@Component({
	selector: 'sh-ag-grid-responsive-wrapper',
	standalone: true,
	imports: [CommonModule, FormsModule, AgGridAngular, NgxResizeObserverModule],
	templateUrl: './ag-grid-responsive-wrapper.component.html',
	styleUrls: ['./ag-grid-responsive-wrapper.component.scss']
})
export class AgGridResponsiveWrapperComponent implements AfterContentInit, AfterViewInit, OnDestroy {
	@Input({ required: true })
	public set gridApi(value: GridApi | null | undefined) {
		if (value) {
			this.localGridApi = value;
			this.localGridApi.addGlobalListener((eventName: string) => {
				this.handleGridApiEvent(eventName);
			});
		}
	}

	/**
	 * Min height that is only used when the no rows overlay is showing.
	 */
	@Input({ required: false }) public minNoRowsHeight?: number;

	/**
	 * Min height that is only used when the loading overlay is showing.
	 */
	@Input({ required: false }) public minLoadingHeight?: number;

	/**
	 * Min height used when there are no rows available, such as when the no rows or loading overlays are shown.
	 * NOTE: the other 2 more specific min height options take precedence over this one
	 */
	@Input({ required: false }) public minHeight?: number;

	public localGridApi!: GridApi;
	public gridElement!: HTMLElement;
	public overlayObserver?: MutationObserver;

	public gridHeight = signal(-1);
	public containerHeight = signal(-1);

	constructor(
		private elementRef: ElementRef,
		private readonly renderer: Renderer2
	) {
		effect(() => {
			const tempContainerHeight = this.containerHeight();
			const tempGridHeight = this.gridHeight();

			// make sure the grid is initialized and we've received updated
			// values for the container and grid before applying a height.
			if (!this.localGridApi || !this.gridElement || tempContainerHeight <= 0 || tempGridHeight <= 0) {
				return;
			}

			const newHeight = Math.min(tempContainerHeight, tempGridHeight);
			this.renderer.setStyle(this.gridElement, 'height', `${newHeight}px`);
		});
	}

	public ngAfterViewInit(): void {
		this.registerOverlayObserver();
		this.containerHeight.update(() => this.elementRef.nativeElement.offsetHeight);
	}

	public ngAfterContentInit(): void {
		this.gridElement = this.elementRef.nativeElement.querySelector('ag-grid-angular');
		if (!this.gridElement) {
			throw new Error('unable to find ag-grid element as child of component');
		}
		this.gridHeight.update(() => this.gridElement.offsetHeight);
	}

	public ngOnDestroy(): void {
		if (this.overlayObserver) {
			this.overlayObserver.disconnect();
		}
	}

	public handleGridContainerResized(event: ResizeObserverEntry): void {
		this.containerHeight.update(() => event.target.clientHeight);
	}

	private handleGridApiEvent(event: string): void {
		switch (event) {
			case 'columnRowGroupChanged':
			case 'displayedColumnsChanged':
			case 'displayedRowsChanged':
			case 'gridSizeChanged':
			case 'bodyHeightChanged':
			case 'gridReady':
				this.gridHeight.update(() => this.computeMaxGridTableHeight());
				break;
			default:
				break;
		}
	}

	private computeMaxGridTableHeight(): number {
		if (!this.localGridApi) return 0;

		const groupDropZoneHeight = this.queryElementHeight('.ag-column-drop');
		const headerHeight = this.queryElementHeight('.ag-header');
		const scrollBarHeight = this.queryElementHeight('.ag-body-horizontal-scroll');
		const statusBarHeight = this.queryElementHeight('.ag-status-bar');

		// w/out the +5, the vertical scrollbar would sometimes appear unnecessarily.
		// testing has shown that 5 is the smallest value required to prevent this.
		const gridShellHeight = groupDropZoneHeight + headerHeight + scrollBarHeight + statusBarHeight + 5;

		let gridBodyHeight = 0;
		this.localGridApi.forEachNodeAfterFilter((node) => {
			// if a row is under a non-expanded group, don't take its height into account
			if (node.displayed) {
				gridBodyHeight += node.rowHeight ?? 0;
			}
		});

		// If there are no rows, compute the min height based on which overlay is showing and what inputs were provided
		// - if the no rows overlay is showing, try to use the minNoRowsHeight
		// - if the loading overlay is showing, try to use the minLoadingHeight
		// - otherwise, use the standard minHeight or the default row height
		if (gridBodyHeight === 0) {
			const noRowsOverlay = this.elementRef.nativeElement.querySelector('.ag-overlay-no-rows-wrapper');
			const loadingOverlay = this.elementRef.nativeElement.querySelector('.ag-overlay-loading-wrapper');

			if (noRowsOverlay && this.minNoRowsHeight !== undefined) {
				gridBodyHeight += this.minNoRowsHeight;
			} else if (loadingOverlay && this.minLoadingHeight !== undefined) {
				gridBodyHeight += this.minLoadingHeight;
			} else {
				gridBodyHeight = this.minHeight ?? this.localGridApi.getGridOption('rowHeight') ?? 50;
			}
		}

		return gridShellHeight + gridBodyHeight;
	}

	private queryElementHeight(selector: string): number {
		const el = this.elementRef.nativeElement.querySelector(selector);
		return el ? el.clientHeight : 0;
	}

	private registerOverlayObserver(): void {
		const overlay: HTMLElement | null = this.elementRef.nativeElement.querySelector('.ag-overlay');
		if (!overlay) {
			return;
		}

		// Observe the overlay component and recompute the grid height whenever the classes change.
		// AG Grid always keeps an element with the .ag-overlay class in the DOM, but adds/removes the
		// .ag-hidden class depending on visibility.
		this.overlayObserver = new MutationObserver(() => {
			this.gridHeight.update(() => this.computeMaxGridTableHeight());
		});

		this.overlayObserver.observe(overlay, { attributeFilter: ['class'] });
	}
}
