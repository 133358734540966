/**
 * Get the overlay div. If the div doesn't exist, null will be returned. If the div should be created if it doesn't
 * exist, this can be specified with the options object.
 *
 * @param options options to configure the behavior of the getter.
 *   - `createIfNonexistent` Whether to create a new overlay div if it doesn't already exist.
 * @returns {HTMLDivElement} the overlay div or null
 */
function getOverlay(options) {
  let overlay = document.getElementById('pwc-overlay');
  if (overlay === null && (options === null || options === void 0 ? void 0 : options.createIfNonexistent)) {
    overlay = document.createElement('div');
    overlay.classList.add('pwc-overlay');
    overlay.id = 'pwc-overlay';
    document.body.insertBefore(overlay, document.body.firstChild);
  }
  return overlay;
}
/**
 * Add a given HTML node to the overlay. This means that the node will be shown on top of all other elements on the
 * page regardless of their z-index and DOM placement.
 *
 * @param node {Node} the HTML node that should be shown in the overlay
 * @returns {HTMLDivElement} the overlay div that the node has been added to
 */
function addToOverlay(node) {
  const overlay = getOverlay({
    createIfNonexistent: true
  });
  overlay.appendChild(node);
  return overlay;
}
/**
 * Remove a given HTML node from the overlay. This means that the given node will no longer be shown on top of all other
 * element on the page regardless of their z-index and DOM placement.
 *
 * @param node {Node} The node that is shown as in the overlay which should be removed
 */
function removeFromOverlay(node) {
  const overlay = getOverlay();
  if (!overlay) return;
  if (overlay.contains(node)) {
    overlay.removeChild(node);
  }
  if (!overlay.hasChildNodes()) {
    document.body.removeChild(overlay);
  }
}
export { addToOverlay as a, getOverlay as g, removeFromOverlay as r };

